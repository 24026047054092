import React, { useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/about/HomeIntro";
import Partners from "../components/about/Partners";
import HiringBlock from "../components/about/HiringBlock";
import Contacts from "../components/about/Contacts";
import Information from "../components/about/Information";

const PgA = () => {
  const [getCurrentLocale, setCurrentLocale] = useState("eu");
  const localizationSwitch = process.env.LOCALIZATION_SWITCH === "true";

  return (
    <Layout customClass="PgA" setLocale={setCurrentLocale}>
      <Seo
        title="AMZScout: About Us"
        description="Here you can find a short user’s guide for our product | AMZScout"
        page="about"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <Information
        getCurrentLocale={getCurrentLocale}
        localizationSwitch={localizationSwitch}
      />
      <Partners />
      <HiringBlock />
      <Contacts
        getCurrentLocale={getCurrentLocale}
        localizationSwitch={localizationSwitch}
      />
    </Layout>
  );
};

export default PgA;
