import React from "react";
import PG_A_INFORMATION_CONTENT from "./index.content";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Information = ({ getCurrentLocale, localizationSwitch }) => {
  const fullText = localizationSwitch
    ? PG_A_INFORMATION_CONTENT[getCurrentLocale || "us"]
    : PG_A_INFORMATION_CONTENT.us;

  return (
    <div className="PgA-Information">
      <div className="container">
        <div
          className={getTrueClasses(
            "PgA-Information__info",
            "PgA-Information-info",
            getCurrentLocale === "eu" && "PgA-Information-info_eu"
          )}
        >
          {fullText.map((text, i) => (
            <p key={i} className="PgA-Information-info__text">
              {text}
            </p>
          ))}
        </div>
        <p className="PgA-Information__text">
          Our core values are growth, teamwork, commitment to the quality of our
          tools, and customer value. This is why we are proud to provide our
          customers with a comprehensive and affordable toolset to successfully
          sell on Amazon from anywhere in the world.
        </p>
      </div>
    </div>
  );
};

export default Information;
